<template>
 <div class="content">
    <div class="con_footer">
      <div class="footer_title"><span>{{user.notitle}}</span>{{user.description}}
       </div>
      <div class="down_app_code">
        <div class="app_code_div" v-for="(item,index) in app_list" :key="index" @mouseenter="app_list[index].type = 1" @mouseleave="app_list[index].type = 0">
          <img class="app_code_div_logo" :src="item.img" alt="">
          <div class="app_code_div_dialog" v-if="item.type">
            <img :src="item.ewm" alt="">
          </div>
        </div>
      </div>
      <div class="footer_site">
          <div class="site_item">
            <img src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/10.png" height="34" width="28"/>
            <div>
              <p>{{user.address}}</p>
            </div>
          </div>
        <div class="site_item">
          <img src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/11.png" height="28" width="28"/>
          <div>
            <p>电话</p>
            <p>{{user.tel}}</p>
          </div>
        </div>
        <div class="site_item">
          <img class="site_item_img" src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/22.png" height="25" width="34"/>
          <div>
            <p>邮箱</p>
            <p>{{user.email}}</p>
          </div>
        </div>
      </div>
      <div class="footer_code">
        <div><img :src="user.appewm" alt=""></div>
        <p>扫码下载APP</p>
      </div>
      <div class="footer_xian"></div>
      <div class="footer_logo">
        <img :src="user.logo" />
        <p @click="bah_tz">{{user.copyright}}<span>备案号：</span>{{user.record}}</p>
      </div>
       <div class="footer_yqlj" v-if="isrouter">
         <p>友情链接:</p>
         <a  v-for="(item,index) in list" :key="index" :href="item.url">{{item.name}}</a>
       </div>
    </div>
 </div>
</template>

<script>

import { mapState } from 'vuex';
import {footer_yqlj} from "@/api/request/all";
export default {
  name: "index",
  data(){
    return{
      app_list:[
        {img:'https://qiniu.fanyu889.com/cxmx/assets/pcimg/12.png',type:0,ewm:''},
        {img:'https://qiniu.fanyu889.com/cxmx/assets/pcimg/15.png',type:0,ewm:''},
        {img:'https://qiniu.fanyu889.com/cxmx/assets/pcimg/24.png',type:0,ewm:''},
        {img:'https://qiniu.fanyu889.com/cxmx/assets/pcimg/28.png',type:0,ewm:''},
      ],
      list:[],
      isrouter:false
    }
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
   // this.all_user();
  },

  mounted() {
   this.all_user();
   this.yqlist();

  },
  methods:{
    //数据请求
    all_user(){
          this.app_list[0].ewm=this.user.gzhewm;
          this.app_list[1].ewm=this.user.sphewm;
          this.app_list[2].ewm=this.user.wxewm;
          this.app_list[3].ewm=this.user.dyewm;
    },
    //备案号通知
    bah_tz(){
      window.open("https://beian.miit.gov.cn/");
    },
    yqlist(){

      if (this.$route.path == '/'){
        this.isrouter =true;
      }else {
        return false;
      }


      footer_yqlj().then((res)=>{
        if(res.status ==1){
          this.list=res.data;
        }
      })
    },
    tz_btn(itrm){
      window.open(itrm.url);
    }
  }
}
</script>

<style scoped lang="scss">
 .content{
   width: 100%;
   height: 537px;
   background: #222222;
   .con_footer{
     width: 1243px;
     margin: 0 auto;
     position: relative;
     padding-top: 1px;
     .footer_title{
       margin-top: 58px;
       img{
         width: 134px;
         height: 31px;
         vertical-align: middle;
         margin-right: 4px;
       }
       span{
         font-weight: bold;
         font-size: 32px;
         color: #FFFFFF;
         background: linear-gradient(0deg, #FFB84D 0%, #FFF7CA 100%);
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
         margin-right: 4px;
         position: relative;
         letter-spacing: 2px;
         top: 3px;
       }
       width: 943px;
       font-weight: 500;
       font-size: 16px;
       color: #EAEAEA;
       line-height: 35px;
     }
     .down_app_code{
       width: 297px;
       height: 45px;
       display: flex;
       justify-content: space-between;
       position: absolute;
       left:0px;
       top:278px;
       .app_code_div{
         width: 45px;
         height: 45px;
         position: relative;
         .app_code_div_logo{
           width: 55px;
           height: 55px;
         }
         .app_code_div_dialog{
           background: url('https://qiniu.fanyu889.com/cxmx/assets/pcimg/34.png') top center no-repeat;
           background-size: 100%;
           width:206px;
           height: 209px;
           position: absolute;
           left: 50%;
           transform: translateX(-50%);
           top: -215px;
           text-align: center;
           img{
             width: 180px;
             height: 180px;
             margin-top: 11px;
           }
         }
       }
     }
     .footer_site{
       display: flex;
       justify-content: space-between;
       width: 600px;
       position: absolute;
       top:288px;
       left: 350px;
       .site_item{
         width:170px;
         display: flex;
         .site_item_img{
           margin-top: 8px;
         }
        div{
          margin-left: 10px;
          padding-top: 2px;
          p:nth-child(1){
            font-weight: 400;
            font-size: 16px;
            color: #ADADAD;
            line-height: 18px;
          }
          p:nth-child(2){
            font-weight: 400;
            font-size: 16px;
            color: #ADADAD;
            line-height: 18px;
          }
        }
       }
     }
     .footer_code{
       width: 192px;
       position: absolute;
       top: 51px;
       right: 0px;
       div{
         width: 192px;
         height: 192px;
         img{
           width: 192px;
           height: 192px;
           background: #999999;
           border-radius: 13px;
         }
       }
       p{
         width: 192px;
         height: 55px;
         background: linear-gradient(90deg, #FFB84D, #FFF7CA);
         border-radius: 13px;
         text-align: center;
         line-height: 55px;
         font-weight: bold;
         font-size: 24px;
         color: #000000;
         margin-top: 21px;
       }
     }
     .footer_xian{
       width: 100%;
       height: 1px;
       background: #999999;
       opacity: 0.38;
       position: absolute;
       top: 370px;
     }
     .footer_logo{
       display: flex;
       justify-content: space-between;
       position: absolute;
       height: 48px;
       top: 398px;
       width: 100%;
       img{
         display: block;
         width: 150px;
         height: 48px;
       }
       p{
         line-height: 48px;
         font-weight: 400;
         font-size: 16px;
         color: #ADADAD;
         span{
           margin-left: 20px;
         }

       }
     }
     .footer_yqlj{
       width: 1035px;
       background: #272727;
       border-radius: 8px;
       padding: 0 100px;
       display: flex;
       position: absolute;
       top: 460px;
       left: 50%;
       transform: translateX(-50%);
       p{
         font-weight: 500;
         font-size: 16px;
         color: #949494;
         line-height: 50px;
         margin-right: 51px;
       }
       a{
         font-weight: 500;
         font-size: 16px;
         color: #949494;
         line-height: 50px;
         margin-right: 51px;
         text-decoration: none
       }
     }
   }

 }
</style>