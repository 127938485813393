<template>
 <div class="content">
   <div class="nav_div">
     <img :src="user.logo" alt="" class="nav_logo">
     <div class="nav_tabs">
       <div v-for="(item ,index) in list" :key="index" @click="router_but(index)">
          <p :class="item.type == 1 ? 'nav_xz':'' ">{{item.name}}</p>
          <p v-if="item.type ==1"></p>
       </div>
     </div>
   </div>

 </div>
</template>

<script>


import {mapState} from "vuex";

export default {
  name: "index",
  data(){
    return{
      list:[
        {name:'首页',type:0,path:'/'},
        {name:'官方活动',type:0,path:'/activity'},
        {name:'冥想资讯',type:0,path:'/message'},
        {name:'隐私政策',type:0,path:'/privacy'},
        {name:'关于我们',type:0,path:'/about'},
        {name:'加入我们',type:0,path:'/join'}
      ],

    }
  },
  created() {
    this.router_jt();
  },
  mounted() {

  },
  computed: {
    ...mapState(['user']),
  },
  methods:{

    router_jt(){
      const currentPath = this.$route.path;
      if (this.$route.query.type == 2 && this.$route.path == '/mesdetail'){
        this.list[1].type =1;
        return false;
      } else  if (this.$route.query.type == 1 && this.$route.path == '/mesdetail'){
        this.list[2].type =1;
        return false;
      }

      for (let i=0; i<  this.list.length;i++ ){
        if ( currentPath == this.list[i].path){
          this.list[i].type =1;
        }else {
          this.list[i].type =0;
        }
      }
    },
    router_but(val){
      this.$router.push({ path:this.list[val].path });
    }
  }
}
</script>

<style scoped lang="scss">
 .content{
   width: 100%;
   height: 67px;
   background: rgba(0,0,0,0.4);
   position: fixed;
   top: 0;
   z-index: 9999;
   .nav_div{
    width: 1243px;
     height: 67px;
     margin:0 auto;
     display: flex;
     justify-content: space-between;
     .nav_logo{
       width: 150px;
       height: 48px;
       position: relative;
       top: 50%;
       transform: translateY(-50%);
     }
     .nav_tabs{
       height: 67px;
       margin: 0;
       display: flex;
       div{
         margin-right: 71px;
          p:nth-child(1){
            line-height: 67px;
            margin: 0;
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
          }
          p:nth-child(2){
            width: 100%;
            height: 2px;
            background: #FFC13B;
            border-radius: 1px;
            margin: 0;
            margin-top: -2px;
         }
          .nav_xz{
            font-weight: bold!important;
            font-size: 20px!important;
            color: #FFC13B!important;
          }
        }
     }
   }
 }
</style>